import React, { Component } from 'react';
import '../../styles/components/splash.scss';
import '../../styles/transitions/remove-splash.scss';

let splash;
let topValue = 0;
let rightValue = 0;

class Splash extends React.Component {

	constructor( props ) {
		super( props );
		this.removeSplash = this.removeSplash.bind( this );
	}

	componentDidMount() {
		setTimeout(() => {
			splash = document.querySelector( '.splash' );
		}, 250);

		setTimeout(() => {
			document.addEventListener( 'mousemove', () => {
				this.removeSplash();
			});
			document.addEventListener( 'click', () => {
				this.removeSplash();
			});
		}, 500);

		setTimeout(() => {
			this.removeSplash();
		}, 5000);
	}

	removeSplash = ( props ) => {
		splash.classList.add( 'hide-splash' );

		setTimeout(() => {
			splash.remove();
		}, 250);

		document.cookie = `splash-viewed=true;`

	}

	render() {

		return (
			<section 
				onWheel={(e) =>  {
					this.removeSplash();
				}} 
				onClick={(e) => {
					this.removeSplash();
				}}
				onTouchStart={(e) => {
					this.removeSplash();
				}}
				className={ 'splash' }>
				<div className={ 'flex' }>
					<h1 className={ 'flex splash-wordmark' }>
						<span>Mooradian</span>
						<span>Studio</span>
					</h1>
				</div>
			</section>
		);

	}

}

export default Splash;
