import React from 'react';
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import { scrollTo } from '../../helpers/scrollTo';
import PanelToggle from './PanelToggle';
import PanelNav from './PanelNav';
import '../../styles/components/panel.scss';
import '../../styles/components/close-panel.scss';
import '../../styles/components/toggle-text.scss';
import '../../styles/components/project-nav.scss';
import '../../styles/content/external-url.scss';
import '../../styles/utils/uppercase.scss';

export default function Panel( props ) {
	let current, currentIndex, nextIndex, previousIndex;
	let groups = document.querySelectorAll( '.group' );

	const previousProject = (e) => {
		let prevCounter = parseInt( props.contentIndex );
		if( prevCounter <= 0 ) return;
		prevCounter--;
		previousIndex = document.querySelector( '[data-index="' + prevCounter + '"]' );
		let scrollThreshold = previousIndex.offsetLeft - ( document.querySelector( '.fixed-title li:nth-of-type(2) a' ).offsetLeft+24 );

		if( props.windowWidth < 631 ) {
			props.closeModal();
		}

		props.setTitle( previousIndex.dataset.title );
		props.setContentIndex( parseInt(prevCounter) );
		scrollTo( scrollThreshold, previousIndex, 2500 );

	}

	const nextProject = (e) => {
		let nextCounter = parseInt( props.contentIndex );
		nextCounter++;
		nextIndex = document.querySelector( '[data-index="' + nextCounter + '"]' );
		let scrollThreshold = nextIndex.offsetLeft - ( document.querySelector( '.fixed-title li:nth-of-type(2) a' ).offsetLeft+24 );
		
		if( props.windowWidth < 631 ) {
			props.closeModal();
		}		

		props.setTitle( nextIndex.dataset.title );
		props.setContentIndex( parseInt(nextCounter) );
		scrollTo( scrollThreshold, nextIndex, 2500 );


	}

	return (
		<>
		<div className="panel flex">			
			<div className="flex text:section">
				<PanelToggle 
					openTextPanel={ props.openTextPanel }
					closeModal={ props.closeModal }
					setContentIndex={ props.setContentIndex }
					modalStatus={ props.modalStatus }
					title={ props.title }
				/>
				<header>
					<ul className="uppercase text:toggle text:tracked">
						{ props.content.location.location && (
							<li>{ props.content.location.location }</li>
						)}

						{ props.content.clientType.clientType && (
							<li>{ props.content.clientType.clientType }</li>
						)}

						{ props.content.year.year && (
							<li>{ props.content.year.year }</li>
						)}

						{ props.content.externalUrl.url && (
							<li className={  props.content.externalUrl.externalLinkSymbol ? 'external-url external-url-symbol' : 'external-url' }>
								<a target="_blank" rel="noreferrer" href={ props.content.externalUrl.url }>
								{ props.content.externalUrl.urlText }</a>
							</li>
						)}
					</ul>

					<PanelNav 
						classes={ 'mobile' }
						contentIndex={ props.contentIndex }
						previousProject={ previousProject }
						nextProject={ nextProject }
						groups={ groups }
					/>		
				</header>

				{ props.content.content && (
					<div className="copy">
						<div 
							dangerouslySetInnerHTML={{ __html: props.content.content }} 
						/>			
					</div>
				)}
				</div>

				<PanelNav 
					classes={ 'desktop' }
					contentIndex={ props.contentIndex }
					previousProject={ previousProject }
					nextProject={ nextProject }
					groups={ groups }
				/>

				<a className={ 'close-panel' } onClick={(e) => {
					e.preventDefault();
					props.closeModal();
				}}></a>

			</div>
		</>
	)

}