import React, { Component, useState, useEffect, useRef } from 'react';
import Spacing from './Spacing';
import Images from './Images';
import { Waypoint } from 'react-waypoint';

import '../../styles/components/group.scss';

import '../../styles/content/large-portrait.scss';
import '../../styles/content/small-portrait.scss';
import '../../styles/content/small-landscape.scss';

import '../../styles/content/small-space.scss';
import '../../styles/content/medium-space.scss';
import '../../styles/content/large-space.scss';

import '../../styles/content/contrast.scss';

let firstTitle, firstGroup, supportsTouch;


class Group extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			visible: null,
			windowWidth: null
		}
	}

	componentDidMount() {
	 	supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

		this.setState({
			windowWidth: this.state.windowWidth = window.innerWidth
		});

		setTimeout(() => {
			this.props.setProjects( this.props.allProjects );
		}, 250);
	}

	render() {
		return (
			<>
			{ !this.props.content.comingSoon.comingSoon && (
				<Waypoint
					horizontal={true}
					onEnter={(e) => {
						this.setState({
							visible: this.state.visible = true,
						})

						if( supportsTouch ) {
							let current = document.querySelectorAll( '.visible-group' )[0].dataset.title;
							this.props.setTitle(current);
						}
					}}
					onLeave={(e) => {
						this.setState({
							visible: this.state.visible = false
						})

						if( supportsTouch ) {
							let current = document.querySelectorAll( '.visible-group' )[0].dataset.title;
							this.props.setTitle(current);
						}

					}}
				>
					<article data-sequence={ this.props.counter + 1 } data-index={ this.props.counter } data-title={ this.props.content.customTitle.customTitle ? this.props.content.customTitle.customTitle : this.props.content.title } className={ this.state.visible === true ? "flex group visible-group" : "flex group" }>
	 					{ this.props.content.imageSequence.imageSequence && this.props.content.imageSequence.imageSequence.map((component, i) => {
	 						if( component.fieldGroupName === "Project_Imagesequence_ImageSequence_Image" ) {
	 							return (
	 								<Images 
	 									setProjects={ this.props.setProjects }
	 									allProjects={ this.props.allProjects }
	 									key={ i }
	 									asset={ component }
	 									panelToggle={ this.props.panelToggle }
	 									setContent={ this.props.setContent }
	 									panelContent={ this.props.content }
	 									selectedImage={ this.props.selectedImage }
	 									setActiveIndex={ this.props.setActiveIndex }
	 									modalStatus={ this.props.modalStatus }
	 									closeModal={ this.props.closeModal }
										openTextPanel={ this.props.openTextPanel }
										setContentIndex={ this.props.setContentIndex }
										clicks={ this.props.clicks }
										incrementClicks={ this.props.incrementClicks }
										resetClicks={ this.props.resetClicks }
										handleLightbox={ this.props.handleLightbox }
	 								/>
	 							)
	 						} else {
	 							return (
	 								<Spacing 
	 									key={ i }
	 									spacing={ component }
	 								/>	
	 							)
	 						}
	 					})}
					</article>
				</Waypoint>
			)}
			</>
		);

	}

}

export default Group;