import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import Cookies from 'js-cookie';
import Header from '../components/Header';
import Layout from '../components/Layout';
import FixedTitle from '../components/home/FixedTitle';
import SEO from '../components/SEO';
import { scrollToIndex } from '../helpers/scrollToIndex';
import Group from '../components/home/Group';
import Panel from '../components/home/Panel';
import Asterism from '../components/home/Asterism';
import Splash from '../components/home/Splash';
import Practice from '../pages/practice';
import LightBox from '../components/home/LightBox';

import '../styles/transitions/index.scss';
import '../styles/transitions/panel.scss';
import '../styles/components/home.scss';

let visible, track, currentNavItem, container;
let isDown = false, isDragged = false, startX, moveLeft, velX, momentumID, touchY, touchX, lastX;
let lightboxCounter = 0;
let theWindow = typeof window !== 'undefined' ? window : '';

class Home extends React.Component {

   scrollRef = React.createRef();
   constructor( props ) {
   	super( props );

   	this.state = {
   		modal: false,
   		index: false,
   		content: '',
   		title: '',
   		counter: null,
   		splash: Cookies.get( 'splash-viewed' ),
   		scrollingOnwards: true,
   		allProjects: [],
   		activeIndex: null,
   		contentIndex: 0,
   		clicks: 0,
   		lightBox: false,
   		activeLightboxIndex: null,
   		windowWidth: theWindow.innerWidth,
   	}

   	this.modalOpen = this.modalOpen.bind( this )
   	this.modalClose = this.modalClose.bind( this )
   	this.handleContent = this.handleContent.bind( this )
   	this.handleWheel = this.handleWheel.bind( this )
   	this.handleResize = this.handleResize.bind( this )
   	this.setProjects = this.setProjects.bind( this )
   	this.setActive = this.setActiveIndex.bind( this )
   	this.setIndexVisibility = this.setIndexVisibility.bind( this )
   	this.setTitle = this.setTitle.bind( this )
   	this.handleLightbox = this.handleLightbox.bind( this )
   	this.handleLightboxClose = this.handleLightboxClose.bind( this )

   }

   	componentDidMount() {
		this.scrollRef.current.addEventListener('wheel', this.handleWheel, {
			passive: false
		});

    	let currentWidth = this.state.windowWidth;

    	window.addEventListener( 'resize', (e) => {
    		if( window.innerWidth === currentWidth ) return;
    		this.handleResize();
    	});

    	setTimeout(() => {
	    	let groups = document.querySelectorAll( '.group' );
			let group = document.querySelector( '.group:first-of-type' );
			let images = document.querySelectorAll( 'figure' );
			container = document.querySelector( '.track' );
			currentNavItem = document.querySelector( '[data-project-index="0"]' );
			group.classList.add( 'visible-group' );
			
			this.setTitle( group.dataset.title );
			this.setActiveIndex( group.dataset.sequence-1 );
			this.setState({ windowWidth: this.state.windowWidth = window.innerWidth });
			console.log(images);
			images.forEach((image, i) => {
				image.setAttribute( 'data-lightbox-index', i );
			});
    	}, 1);

    	setTimeout(() => {
    		container.classList.add( 'loaded' );
    	}, 250);
   	}

   	componentWillUnmount() {
      	this.scrollRef.current.removeEventListener('wheel', this.handleWheel, {
         	passive: false
      	});
   	}

   	setTitle = ( title ) => {
			this.setState({
				title: this.state.title = title
			});
   	}

   	setProjects = ( projects ) => {
			this.setState({
				allProjects: this.state.allProjects = projects
			});  
   	}

   	handleResize = () => {
			this.setState({
				windowWidth: this.state.windowWidth = theWindow.innerWidth,
			});

			if( this.state.windowWidth > 630 ) {
				this.setState({
					lightBox: this.state.lighBox = false
				});

				document.body.classList.remove( 'lightbox-visible' );
			}
   	}

   	handleLightbox = (e) => {
   		e.preventDefault();
   		let lightboxIndex = e.target.closest( 'figure' ).dataset.lightboxIndex;

   		this.setState({
   			lightBox: this.state.lightBox = true,
   			activeLightboxIndex: this.state.activeLightboxIndex = lightboxIndex
   		});

   		setTimeout(() => {
	   		document.body.classList.add( 'lightbox-visible' );
   		}, 1);

   	}

   	handleLightboxClose = (e) => {
   		e.preventDefault();
   		document.body.classList.remove( 'lightbox-visible' );
   	}

   	handleWheel = (e) => {

			e.stopPropagation();
			const containerScrollPosition = this.scrollRef.current.scrollLeft;
			container.scrollTo({
				top: 0,
				left: containerScrollPosition + e.deltaY,
			});

			this.handleScroll();

			if( e.deltaY > 0 ) {
				this.setState({
					scrollingOnwards: this.state.scrollingOnwards = true
				});
			} else {
				this.setState({
					scrollingOnwards: this.state.scrollingOnwards = false
				});
			}

   	}

   	handleScroll = () => {
   		let visibleGroup, visibleGroupFirst, visibleGroupTitle, visibleGroupIndex, correspondingNavItem;
			visibleGroup 			= document.querySelectorAll( '.visible-group' );
			visibleGroupFirst 		= document.querySelectorAll( '.visible-group' );
			visibleGroupTitle 		= visibleGroup[0].dataset.title;
			visibleGroupIndex 		= visibleGroup[0].dataset.index;
			correspondingNavItem 	= document.querySelector( '[data-project-index="' + visibleGroupIndex + '"]' );
			// console.log(visibleGroupTitle);
			let scrollToggle = document.querySelector( '.fixed-title' ).getBoundingClientRect().width;

			if( visibleGroupTitle !== this.state.title ) {
				this.setTitle( visibleGroup[0].dataset.title );
				this.setContentIndex( visibleGroup[0].dataset.index );
			}	

			if( currentNavItem.dataset.projectIndex !== visibleGroupIndex ) {
				currentNavItem.classList.remove( 'active-project' );
				correspondingNavItem.classList.add( 'active-project' );
				currentNavItem = correspondingNavItem;
			}

   	}

   	beginMomentumTracking = () => {
			this.cancelMomentumTracking();
	  		momentumID = requestAnimationFrame(this.momentumLoop); 
	  		this.handleScroll();
   	}

   	cancelMomentumTracking = () => {
  			cancelAnimationFrame(momentumID);
   	}

   	momentumLoop = () => {
	  		velX *= 0.95;
	  		if (Math.abs(velX) > 0.5){ 
	    		momentumID = requestAnimationFrame(this.momentumLoop);
	    	}
	    	this.handleScroll();
   	}
  
   	handleContent = (content) => {
   		this.setState({
   			content: this.state.content = content
   		});
   	}

   	setContentIndex = ( contentIndex ) => {
   		this.setState({
   			content: this.state.content = this.state.allProjects[contentIndex],
   			contentIndex: this.state.contentIndex = contentIndex
   		});
   	}

   	modalOpen = () => {

	   	let height, visibleImages;
		   visible = document.querySelectorAll( '.visible' );
			this.setState({
				modal: this.state.modal = !this.state.modal
			})
			
			setTimeout(() => {
				track = document.querySelector( '.track' );
			}, 1);

			setTimeout(() => {
				document.body.classList.add( 'panel-visible' );
			}, 50);

			setTimeout(() => {
				document.body.classList.add( 'header-fill' );
			}, 350);

			setTimeout(() => {
				document.body.classList.add( 'shrink-large-images' );
			}, 500);

   	}

   modalClose = () => {
	   let shrinkable = document.querySelectorAll( '.shrink' );
		
		setTimeout(() => {
			document.body.classList.remove( 'header-fill' );
		   	document.body.classList.remove( 'panel-visible' );
		}, 100);

	   	setTimeout(() => {
			document.body.classList.remove( 'shrink-large-images' );

		   	this.setState({
		   		modal: this.state.modal = !this.state.modal
		   	})
		 	 
		 	 this.removeShrink();
	   	}, 500);

   }

   indexOpen = () => {

	   	document.body.classList.remove( 'panel-visible' );
	   	this.setState({
	   		index: this.state.index = true,
	   		modal: this.state.modal = false
	   	});

	   	document.body.classList.add( 'has-nav' );
	   	this.removeShrink();

   	}

   	start = ( change ) => {
		startX = change - container.offsetLeft;
		moveLeft = container.scrollLeft;
   	}

   	move = ( change ) => {
   		isDragged = true;
			const containerScrollPosition = this.scrollRef.current.scrollLeft;
			const x = change - container.offsetLeft;
			const walk = (x - startX) * 3; 
			var prevScrollLeft = container.scrollLeft; 
			container.scrollLeft = moveLeft - walk;
			velX = container.scrollLeft - prevScrollLeft; 
   	}

   	indexClose = () => {
			document.body.classList.remove( 'has-nav' );

			setTimeout(() => {
			   	this.setState({
			   		index: this.state.index = false
			   	})
			}, 10);

			setTimeout(() => {
				let list = document.querySelector( '.site-navigation div:last-of-type ul' );
				list.scrollTop = 0;
			}, 250);
   	}

   	removeShrink = () => {
			if( this.state.modal === true ) return;
			visible = document.querySelectorAll( '.visible' );
	   	
	   	visible.forEach((item, index) => {
	   		item.classList.remove( 'shrink' );
	   	});
   	}

   	setActiveIndex = ( selectedIndex ) => {
	   	this.setState({
	   		activeIndex: this.state.activeIndex = selectedIndex,
			content: this.state.content = this.state.allProjects[ this.state.activeIndex ]
	   	});
   	}

	findIndex = ( counter ) => {
		this.indexClose();

		setTimeout(() => {
			scrollToIndex( counter, 24 );	
		}, 500);
	}

	setIndexVisibility = () => {
		this.setState({
			index: false
		});
	}

	render() {
		return (
			<>
				<StaticQuery 
					query={
						graphql`
							query MyQuery {
							  allWpProject(sort: {fields: menuOrder}) {
							    nodes {
							      categories {
							        nodes {
							          name
							        }
							      }
							      imageSequence {
							        imageSequence {
							          ... on WpProject_Imagesequence_ImageSequence_Image {
							            colour
							            fieldGroupName
							            size
							            image {
							              id
							              localFile {
							                childImageSharp {
					                        	fluid(maxWidth: 1920) {
					                          	...GatsbyImageSharpFluid_noBase64
					                        	}
							                }
							                publicURL
							                extension
							              }
							            }
							          }
							          ... on WpProject_Imagesequence_ImageSequence_Space {
							              fieldGroupName
							              space
							            }
							        }
							      }
							      title
							      location {
							        location
							      }
							      year {
							        year
							      }
							      subtitle {
							        subtitle
							      }
							      clientType {
							        clientType
							      }
							      externalUrl {
							      	url
							      	urlText
        							externalLinkSymbol
							      }
							      featuredImage {
							        node {
							          localFile {
							            childImageSharp {
							              fluid {
							                ...GatsbyImageSharpFluid_noBase64
							              }
							            }
							            publicURL
							            extension
							          }
							        }
							      }
							      content
							      comingSoon {
							        comingSoon
							      }
							      customTitle {
							      	customTitle
							      }
							    }
							    edges {
							      node {
							        id
							      }
							    }
							  }
							  wp {
							  generalSettings {
							    title
							    description
							  }
							  siteSettings {
							    settings {
							      address
							      email
							      phone
							    }
							  }
							}

						}`
					}
					render={ ( home )  => (
						<>
							<SEO
								bodyClasses="overflow"
								documentClasses={ 'viewport-control' }
								siteTitle={ home.wp.generalSettings.title }
								siteDescription={ home.wp.generalSettings.description }
								title={ home.wp.generalSettings.title } 
							/>

							<Layout>
								<Header 
									findIndex={ this.findIndex }
									indexOpen={ this.indexOpen }
									navVisibility={ this.indexClose }
									indexVisibility={ this.state.index }
									setTitle={ this.setTitle }
									parentPage={ 'home' }
								/>
								<div
									onClick={(e) => {
										if( this.state.index === true ) {
											this.indexClose();	
										}
										if( isDragged === false && this.state.modal === true ) {
											e.stopPropagation();
											this.modalClose();
										}

										if( this.state.modal === true && isDragged === true ) {
											e.stopPropagation();
										}
									}} 
									onMouseDown={(e) => {
										if( this.state.modal === false ) return;
										isDown = true;
  										container.classList.add('active');
  										this.start( e.pageX );
										this.cancelMomentumTracking();
									}}
									onMouseUp={() => {
										if( this.state.modal === false ) return;
										isDown = false;
  										container.classList.remove('active');
 										this.beginMomentumTracking(); 
 										setTimeout(() => {
	 										isDragged = false;
 										}, 1000);
									}}
									onMouseLeave={() => {
										if( this.state.modal === false ) return;
										isDown = false;
									}}
									onMouseMove={(e) => {
										if( this.state.modal === false ) return;
									  	if(!isDown) return;
									  	isDragged = true;
									  	e.preventDefault();
									  	e.stopPropagation();
									  	this.move( e.pageX );
									}}
									onWheel={(e) => {
										if( this.state.modal === false ) return;
										this.cancelMomentumTracking();
									}}
									onTouchStart={(e) => {
										this.handleScroll();

									}}
									onTouchEnd={(e) => {
										this.beginMomentumTracking(); 
									}}
									onTouchMove={(e) => {
										this.handleScroll();
									}}
									className={ 'home flex elevate' }>
									<div 
										ref={ this.scrollRef } 
										onWheel={() => {
											this.handleWheel();
										}} 
										className="track"
									>

										{ home.allWpProject.nodes.map((group, i) => (
											<Group 
												allProjects={ home.allWpProject.nodes }
												setProjects={ this.setProjects }
												key={ i }
												content={ group }
												counter={i}
												title={ this.state.title }
												setTitle={ this.setTitle }
												panelToggle={ this.modalOpen }
												previousContent={ this.state.previousContent }
												setContent={ this.handleContent }
												setTitle={ this.setTitle }
												setActiveIndex={ this.setActiveIndex }
												scrollDirection={ this.state.scrollingOnwards }
												modalStatus={ this.state.modal }
												closeModal={ this.modalClose }
												openTextPanel={ this.modalOpen }
												setContentIndex={ this.setContentIndex }
												handleLightbox={ this.handleLightbox }
											/>
										)) }

									</div>
								</div>

								<FixedTitle 
									closeModal={ this.modalClose } 
									allProjects={ home.allWpProject.nodes }
									setProjects={ this.setProjects }
									contentIndex={ this.state.contentIndex }
									setContentIndex={ this.setContentIndex }
									modalStatus={ this.state.modal }
									openTextPanel={ this.modalOpen }
									title={ this.state.title } 
								/>
							</Layout>

							<Asterism 
								indexVisibility={ this.state.index }
								handleIndex={ this.indexOpen }
								handleClose={ this.indexClose }
							/>

							{ !this.state.splash && (
								<Splash />
							)}

							{ this.state.modal && (
								<Panel
									setCounter={ this.setCounter }
									counter={ this.state.counter }
									content={ this.state.content } 
									modalStatus={ this.state.modal } 
									closeModal={ this.modalClose }
									openTextPanel={ this.modalOpen }
									scrollToIndex={ scrollToIndex }
									activeIndex={ this.state.activeIndex }
									contentIndex={ this.state.contentIndex }
									setContentIndex={ this.setContentIndex }
									refreshContent={ this.refreshContent }
									setTitle={ this.setTitle }
									title={ this.state.title }
									windowWidth={ this.state.windowWidth }
								/>
							)}

							<LightBox 
								handleLightboxClose={ this.handleLightboxClose }
								visibility={ this.state.lightBox }
								projects={ home.allWpProject }
								activeLightboxIndex={ this.state.activeLightboxIndex }
							/>
						</>
					)}
				/>
			</>

		);

	}

}

export default Home;