import React from 'react';
import PanelToggle from './PanelToggle';

import '../../styles/components/fixed-title.scss';

export default function FixedTitle( props ) {

	const convertTitle = ( title ) => {
		let newTitle = title.replace("<br>","<br />");
		return newTitle;
	}

	return (
		<div className={ 'fixed-title flex' }>
			{ props.title && (
				<p className={ 'text:new-copy text:tracked' } dangerouslySetInnerHTML={{ __html: convertTitle( props.title ) }} />
			)}

			<PanelToggle 
				closeModal={ props.closeModal }
				openTextPanel={ props.openTextPanel }
				setContentIndex={ props.setContentIndex }
				modalStatus={ props.modalStatus }
			/>
		</div>
	);

}