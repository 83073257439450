import React from 'react';

export default function Gif(props) {

	return (
		<figure onClick={ props.handleLightbox } className={ props.size }>
			<img alt={ props.alt } src={ props.source } />
		</figure>
	);

}