import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';
import { scrollToIndex } from '../../helpers/scrollToIndex';
import { scrollTo } from '../../helpers/scrollTo';
import Img from 'gatsby-image';
import Gif from '../utils/Gif';
let click = 0;

class Images extends React.Component {
	constructor( props ) {
		super(props);
		this.state = {
			visible: null,
			expanded: false,
			windowWidth: null
		}
	}

	componentDidMount() {
		
		let activeContent, visibleImages;

		this.setState({
			windowWidth: this.state.windowWidth = window.innerWidth
		});

		if( this.props.asset.size === 'large-portrait' ) {
			this.state.expanded = !this.state.expanded;
		}

	}

	resetSize = () => {

		let images = [...document.querySelectorAll( '.project-image' )];
		let selected = document.querySelector( '.expansion' );

		images.map((image, item) => {
			image.classList.remove( 'expansion' );
			image.classList.remove( 'shrink' );
		});

	}

	checkClasses = ( visibility, size ) => {

		if( visibility === false && size === true ) {
			return "project-image hidden expansion A";
		} else if( visibility === false && size === false ) {
			return "project-image hidden";
		} else if( visibility === true && size === true ) {
			return "project-image visible expansion";
		} else if( visibility === true && size === false ) {
			return "project-image visible";
		} else if( visibility === false && size === true ) {
			return "project-image visible";
		}

	}

	expansion = ( e, size ) => {

		let visibleImages 	= 	document.querySelectorAll( '.visible' );
		[].forEach.call(visibleImages, function(el, i) {

		    if( el.classList.contains( 'expansion' ) ) {
		    	let previous, next, previousImg, nextImg;

		    	if( !visibleImages ) return;

		    	if( visibleImages[i-1] ) {
		    		previous = visibleImages[i-1];
		    	}

		    	if( visibleImages[i+1] ) {
		    		next 	 = visibleImages[i+1];
		    	}

		    	if( previous ) {
				    previousImg = previous.querySelector( 'figure' );
		    	}

		    	if( next ) {
				    nextImg 	= previous.querySelector( 'figure' );
		    	}

		    	if( previousImg.classList.contains( 'large-portrait' ) ) {
		    		previousImg.classList.add( 'shrink' );
		    	}

		    	if( nextImg.classList.contains( 'large-portrait' ) ) {
		    		nextImg.classList.add( 'shrink' );
		    	}

		    }

		});

	}

	render() {	

		return (
			<Waypoint
				horizontal={true}
				onEnter={() => {
					this.setState({
						visible: this.state.visible = true
					})			
				}}
				onLeave={() => {
					this.setState({
						visible: this.state.visible = false
					})
				}}
			>
			<a 	href="#" 
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();

					let current = e.currentTarget;
					let currentContentIndex = document.querySelectorAll( '.visible-group' );
					this.props.setContentIndex( currentContentIndex[0].dataset.index );
					
					if( this.props.asset.size === 'small-portrait' || this.props.asset.size === 'small-landscape' ) {
						e.currentTarget.classList.toggle( 'expansion' );
						this.state.expanded = !this.state.expanded;
					}

				}}
				className={ this.checkClasses( this.state.visible, this.props.asset.size ) }
				data-spacing={ this.props.asset.spacing } 
				data-contrast={ this.props.asset.colour }
			>

				{ this.props.asset.image.localFile.extension === 'gif' && (
					<>
					<Gif handleLightbox={ this.props.handleLightbox } alt={ null } class={ 'gif-wrapper' } size={ this.props.asset.size } source={ this.props.asset.image.localFile.publicURL } />
					</>
				)}

				{ this.props.asset.image.localFile.extension !== 'gif' && (
					<figure onClick={ this.props.handleLightbox } className={ this.props.asset.size } >
						<Img 
							loading={ 'eager' } 
							placeholder={ 'none' } 
							className={ 'project-image-wrapper' } 
							objectFit="contain" 
							fluid={ this.props.asset.image.localFile.childImageSharp.fluid } 
						/>
					</figure>
				)}
			</a>
			</Waypoint>
		)

	}

}

export default Images;