import React from 'react';
import '../../styles/components/panel-toggle.scss';

export default function PanelToggle( props ) {
	let currentProject, currentContentIndex;

	return (
		<ul className={ 'panel-toggle flex text:toggle text:tracked uppercase' }>
			<li className={ 'text:toggle text:tracked uppercase mobile' }>{ props.title }</li>
			<li>
				<a 
				className={ props.modalStatus !== true ? 'active' : '' }
				onClick={(e) => {
					e.preventDefault();
					props.closeModal();
				}}>Images</a>
			</li>
			<li>
				<a 
					className={ props.modalStatus !== false ? 'active' : '' }
					onClick={(e) => {
					currentContentIndex = document.querySelectorAll( '.visible-group' );
					e.preventDefault();
					props.setContentIndex( currentContentIndex[0].dataset.index );
					props.modalStatus === true ? props.closeTextPanel() : props.openTextPanel();
				}}>Text</a>
			</li>
		</ul>
	);

}