import React from 'react';

export default function PanelNav( props ) {

	const reset = () => {
		let copy = document.querySelector( '.copy' );

		if( copy ) {
			copy.scrollTop = 0;
		}
	}

	return (
		<>
			<nav className={ props.classes + ' project-nav' }>
				<ul className={ 'uppercase text:toggle text:tracked' }>
					<li className={ props.contentIndex == 0 ? 'inactive' : '' }>
						<a onClick={(e) => {
							e.preventDefault();
							props.previousProject(e);
							reset();
						}}>Prev</a>	
					</li>
					<li className={ props.contentIndex >= props.groups.length-1 ? 'inactive' : '' }>
						<a onClick={(e) => {
							e.preventDefault();
							props.nextProject(e);
							reset();
						}}>Next project</a>
					</li>
				</ul>
			</nav>
		</>
	);

}