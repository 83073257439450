import React from 'react';
import { Swiper, SwiperSlide, initialSlide } from 'swiper/react';
import Img from 'gatsby-image';
import Gif from '../utils/Gif';
import { scrollTo } from '../../helpers/scrollTo';
import 'swiper/css';
import '../../styles/components/lightbox.scss';

import SwiperCore, {
  Keyboard,
  Navigation,
  Pagination
} from 'swiper';

export default function LightBox( props ) {

	return (
		<>
			<div className={ 'lightbox-container' }>
			<a onClick={
				(e) => {
					props.handleLightboxClose(e)
				}
			} className={ 'close close-lightbox' }></a>
			<Swiper
			  className={ 'lightbox' }
		      spaceBetween={0}
		      slidesPerView={1}
		      onInit={(swiper) => {
		      	swiper.slideTo(props.activeLightboxIndex)

		      	let track = document.querySelector( '.track' );
		      	let images = track.querySelectorAll( 'figure' );

		      	images.forEach((image) => {
		      		image.addEventListener( 'click', (e) => {
		      			e.preventDefault();

		      			let index = e.currentTarget.dataset.lightboxIndex;
		      			swiper.slideTo(index, 0, null);
		      		});
		      	});
		      }}
		      onSlideChangeTransitionEnd={(swiper) => {
		      	let activeIndex = swiper.activeIndex;
		      	let track = document.querySelector( '.home' );
		      	let images = track.querySelector( '[data-lightbox-index="' + activeIndex + '"]' );
		      	const initialPosition = document.querySelector( '.track' ).scrollLeft;
				    scrollTo( images.offsetLeft-12, initialPosition, 250 );

		      }}
		    >
		    { props.projects.nodes.map((project, i) => {
		    	return (
			    	project.imageSequence.imageSequence && project.imageSequence.imageSequence.map((image, k) => {
						if( image.fieldGroupName === 'Project_Imagesequence_ImageSequence_Image' && image.image.localFile.childImageSharp ) {
							return (
								<SwiperSlide>
									<Img 
										loading={ 'eager' } 
										placeholder={ 'none' } 
										className={ 'project-image-wrapper' } 
										objectFit="contain" 
										fluid={ image.image.localFile.childImageSharp.fluid } 
									/>
								</SwiperSlide>
							)
						}    		
			    	}
		    	))
		    }) }
			</Swiper>
			</div>
		</>
	);

}

